<template>
  <Content>
    <template #contentTitle>{{ $t('menu.storage_label_change_create') }}</template>
    <template #contentBody>
      <StepsRow :current="pageStep" />
      <StepZero v-if="pageStep === 0" @next="handleNext" />
      <StepOne v-if="pageStep === 1" :page-state="stepOneState" :warehouse="warehouse" />
    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import Content from '@/views/components/Content.vue';
import StepsRow from './components/StepsRow.vue';
import StepZero from './components/StepZero.vue';
import StepOne from './components/StepOne.vue';

export default defineComponent({
  name: 'storage_label_change_create',
  components: {
    Content,
    StepsRow,
    StepZero,
    StepOne,
  },
  setup () {
    const state = reactive({
      pageStep: 0,
      warehouse: null,
      stepOneState: {
        warehouseId: null,
        taskName: null,
        boxData: [],
      },
    });

    const handleNext = (pageData) => {
      state.pageStep = 1;
      let { warehouseId, taskName, warehouse } = pageData;
      state.stepOneState.warehouseId = warehouseId;
      state.stepOneState.taskName = taskName;
      state.warehouse = warehouse;
    }

    return {
      ...toRefs(state),
      handleNext,
    }
  }
})
</script>

<style scoped></style>