<template>
  <a-modal
    width="800px"
    :title="$t('warehouse.select_box')"
    v-model:visible="visible"
    :centered="true"
    :mask-closable="false"
    @ok="handleConfirm"
  >
    <a-row type="flex" justify="center">
      <a-col>
        <a-row :gutter="16" type="flex" align="middle">
          <a-col>{{ $t('common.user_defined_box_no') }}/{{ $t('warehouse.se_box_number') }}:</a-col>
          <a-col><a-input style="width: 250px;" v-model:value="searchState.boxNo" allow-clear></a-input></a-col>
          <a-col><a-button type="primary" :loading="tableData.loading" @click="handleSearch">{{ $t('common.search') }}</a-button></a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-table
      class="mt-3"
      size="small"
      :columns="columns"
      :data-source="tableData.tableList"
      :pagination="false"
      :scroll="{ x: 500 }"
      :row-key="(record) => record.id"
      :loading="tableData.loading"
    >
      <template #selfBoxNo="{ record }">
        {{ record.selfBoxNo || '-' }}
      </template>
      <template #operation="{ record }">
        <template v-if="record.canChoose">
          <a-button v-if="record.selected" type="primary" @click="handleCancelSelect(record)">{{ $t('common.completed') }}</a-button>
          <a-button v-else type="ghost" @click="handleSelectBox(record)">{{ $t('common.select') }}</a-button>
        </template>
        <a-button v-else disabled>{{ $t('warehouse.temporarily_unavailable') }}</a-button>
      </template>
    </a-table>
    <CPager class="text-center mt-3" :page-data="tableData.pageData" @handlePage="handlePage"></CPager>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import { Button, Col, Input, Modal, Row, Table, message } from 'ant-design-vue';
import CPager from '@/views/components/CPager.vue';
import { useI18n } from "vue-i18n/index";
import { getUserSelectInventoryListPaged } from '@/api/modules/storage/labelChange.js';

export default defineComponent({
  components: {
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AButton: Button,
    ATable: Table,
    CPager,
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      loading: false,
      searchState: {
        warehouseId: null,
        boxNo: null,
      },
      searchStateCache: {},
      selectedBoxList: [],
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        dataIndex: 'boxNo',
        title: () => vueI18n.t('warehouse.se_box_number'),
        width: 200,
      },
      {
        title: () => vueI18n.t('common.user_defined_box_no'),
        width: 200,
        slots: {
          customRender: 'selfBoxNo'
        }
      },
      {
        width: 120,
        title: () => vueI18n.t('common.operation'),
        slots: {
          customRender: 'operation',
        }
      },
    ];

    const handleCancelSelect = (record) => {
      record.selected = false
      let index = state.selectedBoxList.findIndex(item => item.id === record.id);
      if (index > -1) {
        state.selectedBoxList.splice(index, 1);
      }
    }

    const handleSelectBox = (record) => {
      record.selected = true;
      state.selectedBoxList.push(JSON.parse(JSON.stringify(record)));
    }

    const handleConfirm = () => {
      const list = JSON.parse(JSON.stringify(state.selectedBoxList));
      if (list.length > 0) {
        emit('confirm', list);
        close();
      } else {
        message.error(vueI18n.t('warehouse.please_select_box'));
      }
    };

    const getPageList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      getUserSelectInventoryListPaged(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          let boxIdData = state.selectedBoxList.map(item => item.id);
          state.tableData.tableList = items.map(item => {
            if (boxIdData.includes(item.id)) {
              item.selected = true;
            } else {
              item.selected = false;
            }
            return item;
          });
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handleSearch = () => {
      Object.assign(state.searchStateCache, state.searchState);
      handleInitPage();
    };

    const open = (warehouseId, boxData) => {
      state.visible = true;
      state.searchState.warehouseId = warehouseId;
      state.selectedBoxList = JSON.parse(JSON.stringify(boxData));
      state.tableData.tableList = [];
      handleSearch();
    }

    const close = () => {
      state.visible = false;
    }

    return {
      ...toRefs(state),
      columns,
      handleCancelSelect,
      handleSelectBox,
      handleConfirm,
      handlePage,
      handleSearch,
      open,
      close,
    }
  }
})
</script>

<style scoped>

</style>