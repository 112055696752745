<template>
  <a-row :gutter="[16, 8]">
    <a-col :span="24">
      <a-row :gutter="8" type="flex" align="middle">
        <a-col>{{ $t('warehouse.belonging_to_warehouse') }}:</a-col>
        <a-col v-if="warehouse">{{ warehouse.warehouseNo }}</a-col>
        <a-col v-else>-</a-col>
      </a-row>
    </a-col>
    <a-col :span="24">
      <a-row :gutter="8" type="flex" align="middle">
        <a-col>{{ $t('warehouse.label_task_name') }}:</a-col>
        <template v-if="isEditName">
          <a-col>
            <a-input ref="newTaskNameInputRef" v-model:value="newTaskName" :maxlength="128" style="width: 250px;" @blur="handleConfirmTaskName"></a-input>
          </a-col>
          <a-col>
            <a-button type="primary" @click="handleConfirmTaskName">{{ $t('common.confirm') }}</a-button>
          </a-col>
        </template>
        <a-col v-else>
          <span>{{ formState.taskName }} <EditOutlined class="text-primary" style="cursor: pointer;" @click="handleShowInput" /></span>
        </a-col>
      </a-row>
    </a-col>
    <a-col :span="24">
      <a-table
        size="small"
        :columns="columns"
        :data-source="shipmentList"
        :pagination="false"
        :scroll="{ x: 500 }"
        :row-key="(record) => record.id"
      >
        <template #title>
          <a-button type="primary" ghost @click="handleOpenBoxModal">{{ $t('warehouse.select_box') }}</a-button>
        </template>
        <template #selfBoxNo="{ record }">
          {{ record.selfBoxNo || '-' }}
        </template>
        <template #skuNumber="{ record }">
          {{ '1' }}
        </template>
        <template #containerCount="{ record }">
          {{ record.containerCount || '-' }}
        </template>
        <template #operation="{ index }">
          <a-button type="ghost" danger @click="handleDelete(index)">{{ $t('common.delete') }}</a-button>
        </template>
      </a-table>
    </a-col>
    <a-col :span="12"></a-col>
    <a-col :span="12">
      <a-row type="flex" justify="end" class="mt-5 mb-3">
        <a-col class="mr-3">
          <a-button type="primary" :loading="confirmLoading" @click="handleConfirm">{{ $t('warehouse.create_and_next') }}</a-button>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
  <BoxListModal ref="boxListModalRef" @confirm="handleSetBoxList" />
</template>

<script>
import { computed, defineComponent, reactive, toRefs, ref, nextTick, watch } from 'vue';
import { Button, Col, Input, Row, Table, message } from 'ant-design-vue';
import BoxListModal from './BoxListModal';
import { useI18n } from "vue-i18n/index";
import { useRouter } from 'vue-router';
import { setFormStateValue } from '@/utils/general.js';
import { createFirstStep } from '@/api/modules/storage/labelChange.js';
import { useTab } from "@/hooks/tabs/index";
import { refInputElemFocus } from '@/utils/domoperate';

export default defineComponent({
  name: 'stepOne',
  components: {
    ARow: Row,
    ACol: Col,
    AInput: Input,
    AButton: Button,
    ATable: Table,
    BoxListModal,
  },
  props: {
    pageState: {
      type: Object,
      default: () => ({})
    },
    warehouse: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props) {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute } = useTab();

    const boxListModalRef = ref(null);
    const newTaskNameInputRef = ref(null);

    const state = reactive({
      confirmLoading: false,
      isEditName: false,
      newTaskName: null,
      formState: {
        warehouseId: null,
        taskName: null,
        boxData: [],
      },
      shipmentList: []
    });

    const columns = [
      {
        dataIndex: 'boxNo',
        title: () => vueI18n.t('warehouse.se_box_number'),
        width: 150,
      },
      {
        title: () => vueI18n.t('common.user_defined_box_no'),
        width: 150,
        slots: {
          customRender: 'selfBoxNo'
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.sku_number'),
        slots: {
          customRender: 'skuNumber',
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('warehouse.encasement_count'),
        slots: {
          customRender: 'containerCount',
        }
      },
      {
        width: 100,
        title: () => vueI18n.t('common.operation'),
        slots: {
          customRender: 'operation',
        }
      },
    ];

    const getSelectedCount = computed(() => {
      return state.shipmentList.filter(item => item.selected).length;
    });

    const handleShowInput = () => {
      state.isEditName = true;
      nextTick(() => {
        refInputElemFocus(newTaskNameInputRef.value.input);
      });
    }

    const handleConfirmTaskName = () => {
      if (!state.newTaskName) {
        state.newTaskName = state.formState.taskName;
      } else {
        state.formState.taskName = state.newTaskName;
      }
      state.isEditName = false;
    }

    const handleDelete = (index) => {
      state.shipmentList.splice(index, 1);
    }

    const handleOpenBoxModal = () => {
      boxListModalRef.value.open(state.formState.warehouseId, state.shipmentList);
    }

    const handleSetBoxList = (list) => {
      if (Array.isArray(list)) {
        state.shipmentList = list;
      } else {
        state.shipmentList = [];
      }
    }

    const handleConfirm = () => {
      if (!state.formState.taskName) {
        message.warning(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.label_task_name')]));
        return;
      }
      let boxData = state.shipmentList.map(item => ({
        whBoxId: item.id
      }));
      if (boxData.length === 0) {
        message.warning(vueI18n.t('warehouse.please_select_box'));
        return;
      }
      const data = Object.assign({}, state.formState, { boxData })
      state.confirmLoading = true;
      createFirstStep(data).then(({ result }) => {
        message.success(vueI18n.t('common.succeed'));
        handleNext(result);
      }).catch(() => {}).finally(() => {
        state.confirmLoading = false;
      })
    }

    const handleNext = (pageData) => {
      delVisitedRoute(router.currentRoute.value);
      router.push('/storage/labelchange/uploadtags/' + pageData.taskId);
    }

    watch(() => props.pageState, (value) => {
      if (value) {
        setFormStateValue(state.formState, value);
        state.newTaskName = value.taskName;
      }
    }, { deep: true, immediate: true });

    return {
      ...toRefs(state),
      boxListModalRef,
      newTaskNameInputRef,
      columns,
      getSelectedCount,
      handleShowInput,
      handleConfirmTaskName,
      handleDelete,
      handleOpenBoxModal,
      handleSetBoxList,
      handleConfirm,
    }
  }
})
</script>

<style scoped>

</style>